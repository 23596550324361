import React from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown-now';

import backgroundImg from '../images/background.jpg';
import CountItem from './CountItem';
import animationParams from './animation-params';
import media from './media';

const Container = styled.section`
  display: flex;
  align-items: center;
  padding: 8rem 0;
  justify-content: center;
  background: url(${backgroundImg}) rgba(0, 0, 0, 0.4);
  background-blend-mode: overlay;
  background-size: cover;
  flex-direction: column;
  background-position: center;
  color: #fff;
`;

const CountContainer = styled.div`
  display: flex;

  ${media.phone`
    flex-direction: column;
  `}
`;

const TitleContainer = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 4rem;
  margin-bottom: 0;
`;

function CountdownSection() {
  return (
    <Container>
      <TitleContainer>
        <Title {...animationParams}>Đếm ngược tới ngày chung đôi</Title>
      </TitleContainer>
      <Countdown
        date={new Date('30-January-2023 15:00')}
        renderer={({ days, hours, minutes, seconds, completed }) => {
          if (completed) {
            return <p>Done.</p>;
          }
          return (
            <CountContainer>
              <CountItem title="Ngày" count={days} />
              <CountItem title="Giờ" count={hours} />
              <CountItem title="Phút" count={minutes} />
              <CountItem title="Giây" count={seconds} />
            </CountContainer>
          );
        }}
      />
    </Container>
  );
}

export default CountdownSection;
