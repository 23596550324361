import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faCalendar,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';

const Container = styled.div`
  margin-bottom: 5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.primary};
  margin-right: 1rem;
  opacity: 0.8;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  &:not(:first-child) {
    margin: 3rem 0 0;
  }
`;

const QUERY = graphql`
  query {
    event {
      events {
        title
        occasion {
          name
          place {
            name
          }
          time
        }
        calendar {
          google
          outlook
        }
      }
    }
  }
`;

function DateList() {
  const {
    event: { events },
  } = useStaticQuery(QUERY);
  return (
    <div>
      <Container key={events[0].title}>
        <SectionTitle>
          <StyledIcon icon={faCalendar} color="red" />
          <span>
            {events[0].title}
          </span>
        </SectionTitle>
        <List>
          <ListItem key={events[0].occasion.name}>
            <span>
              {events[0].occasion.name}
            </span>
            <p>
              <StyledIcon icon={faMapMarkerAlt} />
              <strong>
                {events[0].occasion.place.name}
              </strong>
            </p>
            <span>
              Tiệc cưới tại nhà gái
            </span>
            <p>
              <StyledIcon icon={faMapMarkerAlt} />
              <strong>
                Nhà văn hoá huyện Kim Bôi, TT Bo, Kim Bôi, Hoà Bình
              </strong>
            </p>
            <p>
              <StyledIcon icon={faClock} />
              <strong>
                {events[0].occasion.time}
              </strong>
            </p>
          </ListItem>
        </List>
      </Container>
      <Container key={events[1].title}>
        <SectionTitle>
          <StyledIcon icon={faCalendar} color="red" />
          <span>
            {events[1].title}
          </span>
        </SectionTitle>
        <List>
          <ListItem key={events[1].occasion.name}>
            <span>
              {events[1].occasion.name}
            </span>
            <p>
              <StyledIcon icon={faMapMarkerAlt} />
              <strong>
                {events[1].occasion.place.name}
              </strong>
            </p>
            <p>
              <StyledIcon icon={faClock} />
              <strong>
                {events[1].occasion.time}
              </strong>
            </p>
          </ListItem>
        </List>
      </Container>
    </div>
  );
}

export default DateList;
