import React, { useEffect } from 'react';
import sal from 'sal.js';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Cover from '../components/Cover';
import Map from '../components/Map';
import CountdownSection from '../components/CountdownSection';
import Profile from '../components/Profile';

const Landing = () => {
  useEffect(() => {
    sal();
  });
  return (
    <div>
      <SEO />
      <Layout>
        <Cover />
        <Profile />
        <CountdownSection />
        <Map />
      </Layout>
    </div>
  );
};

export default Landing;
