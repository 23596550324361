const theme = Object.freeze({
  fonts: {
    main: "'Montserrat', sans-serif",
    heading: "'Great Vibes', cursive",
  },
  colors: {
    primary: '#E86C6C',
    secondary: '#009efd',
    gradient: 'linear-gradient(180deg, #009efd 0%, #2af598  100%)',
    light: '#F5F5F5',
  },
  footer: {
    height: '15rem',
  },
});

export default theme;
